import React from 'react';
/** lib **/
import s from '../../styles/molecules/friend-item.module.scss';
/** style **/
import classNames from 'classnames';

type Props = {
  className?: string;
  characters: string[];
  name: string;
  image: string;
  imageWidth: number;
};

export const FriendItem: React.FC<Props> = ({ className, name, image, imageWidth }) => {
  return (
    <div className={classNames(className, s.friendItemWrapper)}>
      <div className={s.imageWrapper}>
        <img src={require(`assets/images/${image}`)} alt={name} width={imageWidth} />
      </div>
      <p className={s.name}>{name}</p>
    </div>
  );
};
