export const allArea = [
  {
    id: '01',
    label: '目黒川近く 品川駅まで10分',
    type: '都心',
    image: 'area01.webp',
    area: '五反田駅 徒歩8分 ',
    rent: '16.3万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '02',
    label: 'ペットと行けるカフェ等多数',
    type: '都心',
    image: 'area02.webp',
    area: '表参道駅 徒歩8分',
    rent: '19.7万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '03',
    label: 'ペットサロン等サービスも充実',
    type: '都心',
    image: 'area03.webp',
    area: '銀座駅 徒歩5分',
    rent: '22.8万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '04',
    label: 'みなとみらいエリア徒歩圏内',
    type: '都心',
    image: 'area04.webp',
    area: '関内駅 徒歩10分',
    rent: '12.5万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '05',
    label: '目黒川近く 品川駅まで10分',
    type: '都心',
    image: 'area05.webp',
    area: '五反田駅　徒歩8分 ',
    rent: '24.3万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '06',
    label: 'ペットと行けるカフェ等多数',
    type: '都心',
    image: 'area06.webp',
    area: '表参道駅 徒歩8分',
    rent: '31.6万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '07',
    label: 'ペットサロン等サービスも充実',
    type: '都心',
    image: 'area07.webp',
    area: '銀座駅 徒歩5分',
    rent: '36.5万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '08',
    label: 'みなとみらいエリア徒歩圏内',
    type: '都心',
    image: 'area08.webp',
    area: '関内駅 徒歩10分',
    rent: '19.1万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '09',
    label: '代々木公園 徒歩10分圏内',
    type: '大型公園',
    image: 'area09.webp',
    area: '初台駅 徒歩10分 ',
    rent: '15.7万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '010',
    label: '木場公園 徒歩10分圏内',
    type: '大型公園',
    image: 'area010.webp',
    area: '清澄白河駅 徒歩12分',
    rent: '14.3万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '011',
    label: '駒沢公園 徒歩10分圏内',
    type: '大型公園',
    image: 'area011.webp',
    area: '都立大学駅 徒歩15分',
    rent: '15.4万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '012',
    label: '石神井公園 徒歩10分圏内',
    type: '大型公園',
    image: 'area012.webp',
    area: '石神井公園 徒歩15分',
    rent: '15万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '013',
    label: '代々木公園 徒歩10分圏内',
    type: '大型公園',
    image: 'area013.webp',
    area: '初台駅 徒歩10分 ',
    rent: '24.1万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '014',
    label: '木場公園 徒歩10分圏内',
    type: '大型公園',
    image: 'area014.webp',
    area: '清澄白河駅 徒歩12分',
    rent: '22.2万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '015',
    label: '駒沢公園 徒歩10分圏内',
    type: '大型公園',
    image: 'area015.webp',
    area: '都立大学駅 徒歩15分',
    rent: '24万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '016',
    label: '石神井公園 徒歩10分圏内',
    type: '大型公園',
    image: 'area016.webp',
    area: '石神井公園 徒歩15分',
    rent: '18.2万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '017',
    label: '猫の街で有名 谷根千エリア',
    type: '猫フレンドリー',
    image: 'area017.webp',
    area: '千駄木駅 徒歩15分',
    rent: '14.4万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '018',
    label: '猫と縁の深い人気エリア',
    type: '猫フレンドリー',
    image: 'area018.webp',
    area: '神楽坂駅 徒歩10分',
    rent: '15.1万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '019',
    label: '招き猫発祥の地「今戸神社」近く',
    type: '猫フレンドリー',
    image: 'area019.webp',
    area: '南千住駅 徒歩12分',
    rent: '13万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '020',
    label: '猫好きの聖地 江ノ島まで30分',
    type: '猫フレンドリー',
    image: 'area020.webp',
    area: '鎌倉駅 徒歩15分',
    rent: '10.8万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '021',
    label: '猫の街で有名 谷根千エリア',
    type: '猫フレンドリー',
    image: 'area021.webp',
    area: '千駄木駅 徒歩15分',
    rent: '22.4万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '022',
    label: '猫と縁の深い人気エリア',
    type: '猫フレンドリー',
    image: 'area022.webp',
    area: '神楽坂駅 徒歩10分',
    rent: '24.3万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '023',
    label: '招き猫発祥の地「今戸神社」近く',
    type: '猫フレンドリー',
    image: 'area023.webp',
    area: '南千住駅 徒歩12分',
    rent: '20.9万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '024',
    label: '猫好きの聖地 江ノ島まで30分',
    type: '猫フレンドリー',
    image: 'area024.webp',
    area: '鎌倉駅 徒歩15分',
    rent: '16.2万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '025',
    label: '癒しスポット「等々力渓谷」近く',
    type: 'リバーサイド',
    image: 'area025.webp',
    area: '上野毛駅 徒歩15分 ',
    rent: '14.1万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '026',
    label: '多摩川近く 武蔵小杉の隣駅',
    type: 'リバーサイド',
    image: 'area026.webp',
    area: '新丸子駅 徒歩15分',
    rent: '12.15万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '027',
    label: '荒川近く ドッグランあり',
    type: 'リバーサイド',
    image: 'area027.webp',
    area: '赤羽岩淵駅 徒歩10分',
    rent: '15.1万円',
    floorPlan: '1K (32㎡)',
    floorPlanType: '1K',
  },
  {
    id: '028',
    label: '人気湾岸エリア',
    type: 'リバーサイド',
    image: 'area028.webp',
    area: '勝どき駅 徒歩8分',
    rent: '15.1万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '029',
    label: '癒しスポット「等々力渓谷」近く',
    type: 'リバーサイド',
    image: 'area029.webp',
    area: '上野毛駅 徒歩15分 ',
    rent: '22.1万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '030',
    label: '多摩川近く 武蔵小杉の隣駅',
    type: 'リバーサイド',
    image: 'area030.webp',
    area: '新丸子駅 徒歩15分',
    rent: '19.1万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '031',
    label: '荒川近く ドッグランあり',
    type: 'リバーサイド',
    image: 'area031.webp',
    area: '赤羽岩淵駅 徒歩10分',
    rent: '18.1万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '032',
    label: '人気湾岸エリア',
    type: 'リバーサイド',
    image: 'area032.webp',
    area: '勝どき駅 徒歩8分',
    rent: '23.1万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '033',
    label: '東京駅まで30分 旧江戸川近く',
    type: '都心アクセス',
    image: 'area033.webp',
    area: '行徳駅 徒歩15分 ',
    rent: '12.8万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '034',
    label: '路線数全国2位 大宮公園近く',
    type: '都心アクセス',
    image: 'area034.webp',
    area: '大宮駅 徒歩15分',
    rent: '13.7万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '035',
    label: '荒川近く ドッグランあり',
    type: '都心アクセス',
    image: 'area035.webp',
    area: '川口駅 徒歩12分',
    rent: '13.3万円',
    floorPlan: '1K (32㎡)',
    floorPlanType: '1K',
  },
  {
    id: '036',
    label: '４路線利用可能／羽田空港まで20分',
    type: '都心アクセス',
    image: 'area036.webp',
    area: '川崎駅 徒歩15分',
    rent: '13.2万円',
    floorPlan: '1K (25㎡)',
    floorPlanType: '1K',
  },
  {
    id: '037',
    label: '東京駅まで30分 旧江戸川近く',
    type: '都心アクセス',
    image: 'area037.webp',
    area: '行徳駅 徒歩15分 ',
    rent: '19.7万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '038',
    label: '路線数全国2位 大宮公園近く',
    type: '都心アクセス',
    image: 'area038.webp',
    area: '大宮駅 徒歩15分',
    rent: '17.2万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '039',
    label: '荒川近く ドッグランあり',
    type: '都心アクセス',
    image: 'area039.webp',
    area: '川口駅 徒歩12分',
    rent: '16.6万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
  {
    id: '040',
    label: '４路線利用可能／羽田空港まで20分',
    type: '都心アクセス',
    image: 'area040.webp',
    area: '川崎駅 徒歩15分',
    rent: '20.8万円',
    floorPlan: '1LDK(40㎡)',
    floorPlanType: '1LDK',
  },
];

const FloorPlanRentListCity = [
  {
    floorPlanType: '1K',
    type: '都心',
    plans: [
      {
        id: '01',
        label: '目黒川近く 品川駅まで10分',
        type: '都心',
        image: 'area01.webp',
        area: '五反田駅 徒歩8分 ',
        rent: '16.3万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
      {
        id: '02',
        label: 'ペットと行けるカフェ等多数',
        type: '都心',
        image: 'area02.webp',
        area: '表参道駅 徒歩8分',
        rent: '19.7万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
      {
        id: '03',
        label: 'ペットサロン等サービスも充実',
        type: '都心',
        image: 'area03.webp',
        area: '銀座駅 徒歩5分',
        rent: '22.8万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
      {
        id: '04',
        label: 'みなとみらいエリア徒歩圏内',
        type: '都心',
        image: 'area04.webp',
        area: '関内駅 徒歩10分',
        rent: '12.5万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
    ],
  },
  {
    floorPlanType: '1LDK',
    type: '都心',
    plans: [
      {
        id: '05',
        label: '目黒川近く 品川駅まで10分',
        type: '都心',
        image: 'area05.webp',
        area: '五反田駅　徒歩8分 ',
        rent: '24.3万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '06',
        label: 'ペットと行けるカフェ等多数',
        type: '都心',
        image: 'area06.webp',
        area: '表参道駅 徒歩8分',
        rent: '31.6万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '07',
        label: 'ペットサロン等サービスも充実',
        type: '都心',
        image: 'area07.webp',
        area: '銀座駅 徒歩5分',
        rent: '36.5万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '08',
        label: 'みなとみらいエリア徒歩圏内',
        type: '都心',
        image: 'area08.webp',
        area: '関内駅 徒歩10分',
        rent: '19.1万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
    ],
  },
];

const FloorPlanRentListBigPark = [
  {
    floorPlanType: '1K',
    type: '大型公園',
    plans: [
      {
        id: '09',
        label: '代々木公園 徒歩10分圏内',
        type: '大型公園',
        image: 'area09.webp',
        area: '初台駅 徒歩10分 ',
        rent: '15.7万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
      {
        id: '010',
        label: '木場公園 徒歩10分圏内',
        type: '大型公園',
        image: 'area010.webp',
        area: '清澄白河駅 徒歩12分',
        rent: '14.3万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
      {
        id: '011',
        label: '駒沢公園 徒歩10分圏内',
        type: '大型公園',
        image: 'area011.webp',
        area: '都立大学駅 徒歩15分',
        rent: '15.4万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
      {
        id: '012',
        label: '石神井公園 徒歩10分圏内',
        type: '大型公園',
        image: 'area012.webp',
        area: '石神井公園 徒歩15分',
        rent: '15万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
    ],
  },
  {
    floorPlanType: '1LDK',
    type: '大型公園',
    plans: [
      {
        id: '013',
        label: '代々木公園 徒歩10分圏内',
        type: '大型公園',
        image: 'area013.webp',
        area: '初台駅 徒歩10分 ',
        rent: '24.1万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '014',
        label: '木場公園 徒歩10分圏内',
        type: '大型公園',
        image: 'area014.webp',
        area: '清澄白河駅 徒歩12分',
        rent: '22.2万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '015',
        label: '駒沢公園 徒歩10分圏内',
        type: '大型公園',
        image: 'area015.webp',
        area: '都立大学駅 徒歩15分',
        rent: '24万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '016',
        label: '石神井公園 徒歩10分圏内',
        type: '大型公園',
        image: 'area016.webp',
        area: '石神井公園 徒歩15分',
        rent: '18.2万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
    ],
  },
];

const FloorPlanRentListCatFriendly = [
  {
    floorPlanType: '1K',
    type: '猫フレンドリー',
    plans: [
      {
        id: '017',
        label: '猫の街で有名 谷根千エリア',
        type: '猫フレンドリー',
        image: 'area017.webp',
        area: '千駄木駅 徒歩15分',
        rent: '14.4万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
      {
        id: '018',
        label: '猫と縁の深い人気エリア',
        type: '猫フレンドリー',
        image: 'area018.webp',
        area: '神楽坂駅 徒歩10分',
        rent: '15.1万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
      {
        id: '019',
        label: '招き猫発祥の地「今戸神社」近く',
        type: '猫フレンドリー',
        image: 'area019.webp',
        area: '南千住駅 徒歩12分',
        rent: '13万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
      {
        id: '020',
        label: '猫好きの聖地 江ノ島まで30分',
        type: '猫フレンドリー',
        image: 'area020.webp',
        area: '鎌倉駅 徒歩15分',
        rent: '10.8万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
    ],
  },
  {
    floorPlanType: '1LDK',
    type: '猫フレンドリー',
    plans: [
      {
        id: '021',
        label: '猫の街で有名 谷根千エリア',
        type: '猫フレンドリー',
        image: 'area021.webp',
        area: '千駄木駅 徒歩15分',
        rent: '22.4万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '022',
        label: '猫と縁の深い人気エリア',
        type: '猫フレンドリー',
        image: 'area022.webp',
        area: '神楽坂駅 徒歩10分',
        rent: '24.3万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '023',
        label: '招き猫発祥の地「今戸神社」近く',
        type: '猫フレンドリー',
        image: 'area023.webp',
        area: '南千住駅 徒歩12分',
        rent: '20.9万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '024',
        label: '猫好きの聖地 江ノ島まで30分',
        type: '猫フレンドリー',
        image: 'area024.webp',
        area: '鎌倉駅 徒歩15分',
        rent: '16.2万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
    ],
  },
];

const FloorPlanRentListRiverSide = [
  {
    floorPlanType: '1K',
    type: 'リバーサイド',
    plans: [
      {
        id: '025',
        label: '癒しスポット「等々力渓谷」近く',
        type: 'リバーサイド',
        image: 'area025.webp',
        area: '上野毛駅 徒歩15分 ',
        rent: '14.1万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
      {
        id: '026',
        label: '多摩川近く 武蔵小杉の隣駅',
        type: 'リバーサイド',
        image: 'area026.webp',
        area: '新丸子駅 徒歩15分',
        rent: '12.15万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
      {
        id: '027',
        label: '荒川近く ドッグランあり',
        type: 'リバーサイド',
        image: 'area027.webp',
        area: '赤羽岩淵駅 徒歩10分',
        rent: '15.1万円',
        floorPlan: '1K (32㎡)',
        floorPlanType: '1K',
      },
      {
        id: '028',
        label: '人気湾岸エリア',
        type: 'リバーサイド',
        image: 'area028.webp',
        area: '勝どき駅 徒歩8分',
        rent: '15.1万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
    ],
  },
  {
    floorPlanType: '1LDK',
    type: 'リバーサイド',
    plans: [
      {
        id: '029',
        label: '癒しスポット「等々力渓谷」近く',
        type: 'リバーサイド',
        image: 'area029.webp',
        area: '上野毛駅 徒歩15分 ',
        rent: '22.1万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '030',
        label: '多摩川近く 武蔵小杉の隣駅',
        type: 'リバーサイド',
        image: 'area030.webp',
        area: '新丸子駅 徒歩15分',
        rent: '19.1万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '031',
        label: '荒川近く ドッグランあり',
        type: 'リバーサイド',
        image: 'area031.webp',
        area: '赤羽岩淵駅 徒歩10分',
        rent: '18.1万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '032',
        label: '人気湾岸エリア',
        type: 'リバーサイド',
        image: 'area032.webp',
        area: '勝どき駅 徒歩8分',
        rent: '23.1万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
    ],
  },
];

const FloorPlanRentSliderAccess = [
  {
    floorPlanType: '1K',
    type: '都心アクセス',
    plans: [
      {
        id: '033',
        label: '東京駅まで30分 旧江戸川近く',
        type: '都心アクセス',
        image: 'area033.webp',
        area: '行徳駅 徒歩15分 ',
        rent: '12.8万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
      {
        id: '034',
        label: '路線数全国2位 大宮公園近く',
        type: '都心アクセス',
        image: 'area034.webp',
        area: '大宮駅 徒歩15分',
        rent: '13.7万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
      {
        id: '035',
        label: '荒川近く ドッグランあり',
        type: '都心アクセス',
        image: 'area035.webp',
        area: '川口駅 徒歩12分',
        rent: '13.3万円',
        floorPlan: '1K (32㎡)',
        floorPlanType: '1K',
      },
      {
        id: '036',
        label: '４路線利用可能／羽田空港まで20分',
        type: '都心アクセス',
        image: 'area036.webp',
        area: '川崎駅 徒歩15分',
        rent: '13.2万円',
        floorPlan: '1K (25㎡)',
        floorPlanType: '1K',
      },
    ],
  },
  {
    floorPlanType: '1LDK',
    type: '都心アクセス',
    plans: [
      {
        id: '037',
        label: '東京駅まで30分 旧江戸川近く',
        type: '都心アクセス',
        image: 'area037.webp',
        area: '行徳駅 徒歩15分 ',
        rent: '19.7万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '038',
        label: '路線数全国2位 大宮公園近く',
        type: '都心アクセス',
        image: 'area038.webp',
        area: '大宮駅 徒歩15分',
        rent: '17.2万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '039',
        label: '荒川近く ドッグランあり',
        type: '都心アクセス',
        image: 'area039.webp',
        area: '川口駅 徒歩12分',
        rent: '16.6万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
      {
        id: '040',
        label: '４路線利用可能／羽田空港まで20分',
        type: '都心アクセス',
        image: 'area040.webp',
        area: '川崎駅 徒歩15分',
        rent: '20.8万円',
        floorPlan: '1LDK(40㎡)',
        floorPlanType: '1LDK',
      },
    ],
  },
];

export const allAreaByType = FloorPlanRentListCity.concat(
  FloorPlanRentSliderAccess,
  FloorPlanRentListRiverSide,
  FloorPlanRentListCatFriendly,
  FloorPlanRentListBigPark,
);
