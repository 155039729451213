export const ComicsList = [
  {
    type: '急な出張時でも',
    nomalImages: ['comic-nomal01.png', 'comic-nomal02.png', 'comic-nomal03.png', 'comic-nomal04.png'],
    images: ['comic01.png', 'comic02.png', 'comic03.png', 'comic04.png'],
  },
  {
    type: 'シニアペットも安心',
    nomalImages: ['comic-nomal05.png', 'comic-nomal06.png', 'comic-nomal07.png', 'comic-nomal08.png'],
    images: ['comic05.png', 'comic06.png', 'comic07.png', 'comic08.png'],
  },
  {
    type: '運動不足解消',
    nomalImages: ['comic-nomal09.png', 'comic-nomal010.png', 'comic-nomal011.png', 'comic-nomal012.png'],
    images: ['comic09.png', 'comic010.png', 'comic011.png', 'comic012.png'],
  },
  {
    type: '仲間との情報シェア',
    nomalImages: ['comic-nomal013.png', 'comic-nomal014.png', 'comic-nomal015.png', 'comic-nomal016.png'],
    images: ['comic013.png', 'comic014.png', 'comic015.png', 'comic016.png'],
  },
];
