/* eslint-disable array-callback-return */
import React, { useRef } from 'react';
/** lib **/
import { useInViewFadeUp } from 'lib/use-in-view';
import 'swiper/scss';
import classNames from 'classnames';
import { FriendItemList } from 'lib/friends-list';
/** components **/
import { ContentsTitle, FriendItem } from '../molecules';
/** styles **/
import s from '../../styles/organisms/friends.module.scss';

export const Friends: React.FC = () => {
  const inViewRef1 = useRef<HTMLHeadingElement>(null);
  const inViewRef2 = useRef<HTMLHeadingElement>(null);
  useInViewFadeUp([inViewRef1, inViewRef2]);

  return (
    <div className={s.friendsWrapper}>
      <div className={s.inner}>
        <div className={s.beforeFadeUp} ref={inViewRef1}>
          <ContentsTitle className={s.contentsTitle} title="FRIENDS of P-man" subTitle="ピーマンの仲間たち" />
        </div>
        <div className={classNames(s.friendList, s.beforeFadeUp)} ref={inViewRef2}>
          {FriendItemList.map((item, index: React.Key) => {
            return (
              <FriendItem
                key={index}
                characters={item.characters}
                name={item.name}
                image={item.image}
                imageWidth={item.imageWidth}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
