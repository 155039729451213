export const FriendItemList = [
  {
    name: 'ピーワン',
    characters: [
      'おっとり。素直。',
      'オスっぽい見た目だが、特に性別はないらしい。',
      '犬のようだが、動物なのか野菜なのか謎。',
      '鳴き声は「ワン」と聞こえるが、時々「ピー」と小さく鳴く時もある',
      '種から植物のように育ち、兄弟が沢山いる。',
      '収穫の時に、マズルの形しだいではピーニャになるらしい。',
      '好きな子は「パプリカ」',
    ],
    image: 'friend01.png',
    imageWidth: 242,
  },
  {
    name: 'ピーニャ',
    characters: [
      'はずかしがり屋だが、好奇心は旺盛。なにかあると覗かずにはいられない。',
      '鳴き声は「ニャ」と聞こえるが、時々「ピー」と小さく鳴く時もある',
      '一説では、しっぽを切るとピーワンになる、ともいわれている。',
      '好きな食べ物は、ひき肉とたけのこ。中華風味が好き',
    ],
    image: 'friend02.png',
    imageWidth: 171,
  },
  {
    name: 'ピー',
    characters: [
      '「ピー」としか鳴かない。',
      '仲間好きで群れる習性があるが、単独行動も好き',
      '羽を広げて飛ぶ時に種を落とすので、どんどん増殖する。',
      '細長い体形の亜種もおり、中には激辛がまざっている。',
    ],
    image: 'friend03.png',
    imageWidth: 113,
  },
  {
    name: 'パプリカ',
    characters: [
      '穏やかそうに見えて、猪突猛進。',
      '潤んだ大きな瞳、近づくとフルーツのような甘い香りがほのかにする。',
      'メスっぽい見た目だが、特に性別はないらしい。',
      '鳴き声は「ワン」と聞こえるが、よく「パプパプ」と鼻を鳴らす。',
      '興奮すると「パプ――」と鼻を鳴らしながら突進してくる。',
    ],
    image: 'friend04.png',
    imageWidth: 224,
  },
];
